:root {
  /* Sizes */
  --formMaxWidth: 30em;
  --padding-top-std: 1em;
  --padding-right-std: 1em;
  --padding-bottom-std: 1em;
  --padding-left-std: 1em;
  --header-height: 6em;
  --footer-height: 2em;

  /* Colors */
  --lightGold: #dbba83;
  --mediumGold: #C9AA79;
  --darkGold: #b38c4e;

  --lightEmerald: #02811b;
  --mediumEmerald: #01480F;
  --darkEmerald: #002507;


  --lightMainColor: var(--lightGold);
  --mediumMainColor: var(--mediumGold);
  --darkMainColor: var(--darkGold);

  --white: #FFFFFF;
  --lightGrey: #FAFAFA;
  --midGrey: #DBDBDB;
  --darkGrey: #4E4F50;
  --lightBlack: #242526;
  --darkBlack: #18191A;
  --pitchBlack: #000000;



  /* Light Mode */
  --bg-color-primary: var(--lightGrey);
  --font-color-primary: var(--darkBlack);
  --border-color-primary: var(--midGrey);

  --bg-color-secondary: var(--white);
  --font-color-secondary: var(--lightBlack);
  --border-color-secondary: var(--midGrey);

  --bg-color-tertiary: var(--lightMainColor);
  --font-color-tertiary: var(--white);
  --border-color-tertiary: var(--mediumMainColor);

  --bg-color-quarternary: var(--darkGrey);
  --font-color-quarternary: var(--lightMainColor);
  --border-color-quarternary: var(--lightGrey);
}

/* Dark Mode */
@media (prefers-color-scheme: dark) {
  :root {
    --bg-color-primary: var(--lightBlack);
    --font-color-primary: var(--white);
    --border-color-primary: var(--pitchBlack);
    
    --bg-color-secondary: var(--darkBlack);
    --font-color-secondary: var(--white);
    --border-color-secondary: var(--pitchBlack);
    
    --bg-color-tertiary: var(--lightMainColor);
    --font-color-tertiary: var(--lightBlack);
    --border-color-tertiary: var(--darkMainColor);

    --bg-color-quarternary: var(--darkBlack);
    --font-color-quarternary: var(--lightMainColor);
    --border-color-quarternary: var(--pitchBlack);
  }
}

html {
  scroll-behavior: smooth;
}


/* Type */
@font-face {
  font-family: "Butler";
  src: local("Butler"),
    url("./Fonts/Butler_and_ButlerStencil/Butler_Webfont/Butler-Bold.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "Karla";
  src: local("Karla"),
    url("./Fonts/karla/static/Karla-Regular.ttf") format("woff");
  font-weight: normal;
}

* {
  font-family: Karla;
  letter-spacing: 2px;
  line-height: 2;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Butler';
  text-transform: uppercase;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 3px;
}

h1, h3, h5 {
  color: var(--font-color-quarternary);
}

h1 {
  font-size: 1.8rem;
}

/* General */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

a, a:visited, .btn {
  padding: calc(var(--padding-top-std) * 0.75) var(--padding-left-std);
  color: var(--font-color-primary);
  text-decoration: none;
  text-align: center;
}

section {
  margin: auto;
  max-width: 75em;
  width: 100%;
  padding: var(--padding-top-std) var(--padding-left-std);
}

#root {
  position: relative;
}

:is(header, footer) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 5;
}

header {
  background-color: var(--bg-color-secondary);
  border-bottom: solid 1px var(--border-color-secondary);
  min-height: var(--header-height);
  top: 0;
}

header > section > * {
  color: var(--font-color-secondary);
}

footer {
  background-color: var(--bg-color-tertiary);
  color: var(--font-color-tertiary);
  border-color: var(--border-color-tertiary);
  min-height: var(--footer-height);
  bottom: 0;
}

body {
  background-color: var(--bg-color-primary);
  color: var(--font-color-primary);
  border-color: var(--border-color-primary);
  min-height: 100vh;
}

body > section {
  padding: calc(var(--padding-top-std) * 5) var(--padding-left-std);
}

body > section:first-child {
  padding-top: calc(var(--header-height) + (5 * var(--padding-top-std)));
}

body > section:last-child {
  padding-bottom: calc(var(--footer-height) + (5 * var(--padding-bottom-std)));
}

.card {
  background-color: var(--bg-color-secondary);
  color: var(--font-color-secondary);
  border: solid 1px var(--border-color-secondary);
  padding: var(--padding-top-std);
  border-radius: 3px;
}

.btn, .btn:visited {
  font-family: 'Butler';
  font-weight: normal;
  font-style: normal;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 2px;
  text-decoration: none;
  background-color: var(--bg-color-tertiary);
  color: var(--font-color-tertiary);
  transition: background-color .3s ease-in-out, color .3s ease-in-out, filter .2s ease-in-out;
}

.btn:hover, .btn:focus-within {
  background-color: var(--bg-color-quarternary);
  color: var(--font-color-quarternary);
  border-color: var(--border-color-quarternary);
}